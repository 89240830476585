.table-component {
       
    tr {
        vertical-align: baseline;
        cursor: pointer;

        * {
            user-select: none;
            // touch-action: none;
        }
    }


    tbody {

        -webkit-overflow-scrolling: auto;
        tr {
            border-bottom: 1px solid #AAAAAA;
            &:active {
                background: rgba($color: #42ACDE, $alpha: .4);
            }

            &.selected {
                background: rgba($color: #42ACDE, $alpha: .4);
            }
            td {
                font-size: calc(1rem - 5px);
                border-bottom: 1px solid #CCC;
            }
        }
    }


    th {
        position: sticky;
        top: -1px;
        background: #FFF;
        color: #AAAAAA;
        border: none !important;
        text-align: left;
        z-index: 999;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 1px;
            // width: 100%;
            border-bottom: 1px solid #AAAAAA;
        }
        font-size: calc(1rem - 5px);
        i {
            transform: scale(1.5) translate(1.5px, 3px) rotate(-180deg);
            &.desc {
                transform: scale(1.5) translate(1.5px, -3px);
            }
        }
        &.active {
            color: #000;
        }
    }

    th,
    td {
        padding-top: 12px;
        padding-bottom: 12px;
        &:first-child {
            padding-left: 12px;
        }
        &:last-child {
            padding-right: 12px;
        }
    }

    table {
        width: 100%;

        white-space: nowrap;

        h4 {
            font-size: calc(1rem - 5px);
            &.light {
                font-weight: 300;
                margin-top: .25em;
            }
            &.italic {
                font-weight: 300;
                font-style: italic;
                margin-top: .25em;
            }
        }
    }

   
    .icon-container {
        display: flex;
        align-items: center;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        
        justify-content: center;
        background: #F5F8FA;
        outline: none !important;
        border: none;
        transition: .3s all ease;
        cursor: pointer;
        &:active {
            opacity: .7;
        }
    }


    .spin {
        animation: 1s spin forwards linear infinite;
        opacity: .7;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .my-menuitem {
        font-size: .9rem;
    }
}