.load-spinner-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 999999;
    div.loading-spinner-container {
        // background: #FFF;
        border-radius: 1rem;
        max-width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2rem;
        width: 90%;
        img {
            width: 100%;
            margin-bottom: 1rem;
            // background: ;

        }
        h6,
        span {
            font-size: 1.2rem;
            text-align: center;
            font-weight: 400;
            position: relative;
            div {
                position: absolute;
                right: 0;
                top: 0;
                width: 99%;
                height: 100%;
                background: #FFF;
                animation: loading-loop 1s linear infinite;
            }
        }

    }
    @keyframes loading-loop {
        0% {
            width: 100%;
        }
        25% {
            width: 66%;
        }
        50% {
            width: 33%;
        }
        75% {
            width: 0%;
        }
        100% {
            width: 0;
        }
    }
}