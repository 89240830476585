nav.navbar-component {
   
    list-style: none;
    ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        li {
            margin-right: .75em;
            &:last-child {
                margin-right: 0;
            }
            a {
                text-decoration: none;
                color: #AAA;
                font-size: calc(1em - 2px);
                font-weight: 600;
                &:hover {
                    color: rgb(61, 61, 61);
                }
                &.active {
                    color: #000;
                }
            }            
        }
    }
    
    button {
        display: inline-block;
        min-width: calc(50% - .5rem) !important;
        max-width: calc(50% - .5rem) !important;
        padding: 1.5rem !important;
        margin-bottom: 1rem;
        font-size: .6rem;
        font-weight: 300 !important;
    }
    a {
        text-decoration: none;
        color: #000;
    }
    button {
        background: #FFF;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        i {
            font-size: 10rem;
            margin: 1rem 0;
        }
        div {
            font-size: 2rem;
        }
    }
}

@media screen and (max-width: 767px)
{
    nav.navbar-component {
        button {
            min-width: 100% !important;
            max-width: 100% !important;
            i {
                font-size: 4rem;
            }
            div {
                font-size: 1rem;
            }
        }
     }
}