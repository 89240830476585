.pay-charge-component {
    padding: 2rem;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .pay-header {
        position: fixed;
        background-color: #FFF;
        left: 0;
        top: 0;
        box-shadow: 0 2px 6px #00000029;
        width: 100%;
        div {
            max-width: 1200px;
            width: 90%;
            margin: 0 auto;

            padding: 1rem 2rem;

            img {
                width: 2rem;
            }
        }
    }

    .order-summary {
        // box-shadow: 0 3px 6px #00000029;
        border-radius: 1rem;
        h5.summary-title {
            font-size: calc(1rem);
        }
        .summary-header {
            // border-bottom: 1px solid #CCC;
            padding: 1rem;
        }
        .summary-footer {
            border-top: 1px solid #CCC;
            padding: 1rem;
        }
    }

}