.settings-component {
    
    .tabs {
        p {
            transition: .3s all ease;
            cursor: pointer;
            user-select: none;
            border-bottom: 2px solid transparent;
            &.active {
                border-color: #42ACDE;
            }
        }
    }
    
}