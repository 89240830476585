.login-component {
    .login-container {
        padding-top: 120px;
        width: 90%;
        max-width: 272px;
        margin: 0 auto;
    }
    
    .login-container h4 {
        margin-bottom: 20px;
        font-weight: 600 !important;
        font-size: 14px;
    }
    
    .input-group {
        position: relative;
        margin-bottom: 12px;
    }
    
    .input-group img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 16px;
        width: 16px;
    }
    
    .input-group img.left {
        left: 20px;
    }
    
    .input-group img.right {
        right: 16px;
    }
    
    input {
        width: 100%;
        padding: 12px 38px 12px 48px;
        background: #F4F4F4;
        border-radius: 8px;
        border: none;
        outline: none;
        font-size: 12px;
    }
    
    button {
        margin-top: 24px;
        font-size: 10px;
        padding: 10px 51px;
        background-color: #F28705;
        color: #FFF;
        border: none;
        outline: none;
        border-radius: 20px;
        display: block;
        margin-left: auto;
        transition: .3s all ease;
        font-weight: 600;
    }
    
    button:disabled {
        opacity: .3;
    }
    
    button:active {
        opacity: .7;
    }
    
    
    p.wrong {
        color: #F28705;
        font-weight: 600;
        font-size: 10px;
        text-align: right;
    }
    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    
    .loading img {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}