div.main-container {
    max-width: 1000px;
    width: 90%;
    margin: 0 auto;
    height: calc(100% - 4rem);
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.main-content {
    margin: 1em 0;
    height: 100%;
    border-radius: 1em;
    box-shadow: 3px 4px 12px #00000029;
    overflow: auto;
}

.inner-content {
    overflow: auto;
    max-height: calc(100% - 4rem);
    min-height: calc(100% - 4rem);
    margin: 1rem;
    position: relative;
    
}
.inner-content.home {

    max-height: calc(100% - 2rem) !important;
    min-height: calc(100% - 2rem) !important;
}

