body,
html,
#root {
  height: 100%;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}


body {
  font-size: 16px;
  font-family: 'Poppins', sans-serif !important;
}


// @media screen and (max-width: 767px)
// {
//   body {
//     font-size: 16px;
//   }
// }



/* MARGINS / PADDINGS */

.m-0 { margin:0!important; }
.m-1 { margin:.25rem!important; }
.m-2 { margin:.5rem!important; }
.m-3 { margin:1rem!important; }
.m-4 { margin:1.5rem!important; }
.m-5 { margin:3rem!important; }

.mt-0 { margin-top:0!important; }
.mr-0 { margin-right:0!important; }
.mb-0 { margin-bottom:0!important; }
.ml-0 { margin-left:0!important; }
.mx-0 { margin-left:0!important;margin-right:0!important; }
.my-0 { margin-top:0!important;margin-bottom:0!important; }

.mt-1 { margin-top:.25rem!important; }
.mr-1 { margin-right:.25rem!important; }
.mb-1 { margin-bottom:.25rem!important; }
.ml-1 { margin-left:.25rem!important; }
.mx-1 { margin-left:.25rem!important;margin-right:.25rem!important; }
.my-1 { margin-top:.25rem!important;margin-bottom:.25rem!important; }

.mt-2 { margin-top:.5rem!important; }
.mr-2 { margin-right:.5rem!important; }
.mb-2 { margin-bottom:.5rem!important; }
.ml-2 { margin-left:.5rem!important; }
.mx-2 { margin-right:.5rem!important;margin-left:.5rem!important; }
.my-2 { margin-top:.5rem!important;margin-bottom:.5rem!important; }

.mt-3 { margin-top:1rem!important; }
.mr-3 { margin-right:1rem!important; }
.mb-3 { margin-bottom:1rem!important; }
.ml-3 { margin-left:1rem!important; }
.mx-3 { margin-right:1rem!important;margin-left:1rem!important; }
.my-3 { margin-bottom:1rem!important;margin-top:1rem!important; }

.mt-4 { margin-top:1.5rem!important; }
.mr-4 { margin-right:1.5rem!important; }
.mb-4 { margin-bottom:1.5rem!important; }
.ml-4 { margin-left:1.5rem!important; }
.mx-4 { margin-right:1.5rem!important;margin-left:1.5rem!important; }
.my-4 { margin-top:1.5rem!important;margin-bottom:1.5rem!important; }

.mt-5 { margin-top:3rem!important; }
.mr-5 { margin-right:3rem!important; }
.mb-5 { margin-bottom:3rem!important; }
.ml-5 { margin-left:3rem!important; }
.mx-5 { margin-right:3rem!important;margin-left:3rem!important; }
.my-5 { margin-top:3rem!important;margin-bottom:3rem!important; }

.mt-auto { margin-top:auto!important; }
.mr-auto { margin-right:auto!important; }
.mb-auto { margin-bottom:auto!important; }
.ml-auto { margin-left:auto!important; }
.mx-auto { margin-right:auto!important;margin-left:auto!important; }
.my-auto { margin-bottom:auto!important;margin-top:auto!important; }

.p-0 { padding:0!important; }
.p-1 { padding:.25rem!important; }
.p-2 { padding:.5rem!important; }
.p-3 { padding:1rem!important; }
.p-4 { padding:1.5rem!important; }
.p-5 { padding:3rem!important; }

.pt-0 { padding-top:0!important; }
.pr-0 { padding-right:0!important; }
.pb-0 { padding-bottom:0!important; }
.pl-0 { padding-left:0!important; }                             
.px-0 { padding-left:0!important;padding-right:0!important; }
.py-0 { padding-top:0!important;padding-bottom:0!important; }

.pt-1 { padding-top:.25rem!important; }         
.pr-1 { padding-right:.25rem!important; }                       
.pb-1 { padding-bottom:.25rem!important; }      
.pl-1 { padding-left:.25rem!important; }                            
.px-1 { padding-left:.25rem!important;padding-right:.25rem!important; }
.py-1 { padding-top:.25rem!important;padding-bottom:.25rem!important; }

.pt-2 { padding-top:.5rem!important; }                                              
.pr-2 { padding-right:.5rem!important; }                                
.pb-2 { padding-bottom:.5rem!important; }               
.pl-2 { padding-left:.5rem!important; }                                             
.px-2 { padding-right:.5rem!important;padding-left:.5rem!important; }
.py-2 { padding-top:.5rem!important;padding-bottom:.5rem!important; }

.pt-3 { padding-top:1rem!important; }                               
.pr-3 { padding-right:1rem!important; }             
.pb-3 { padding-bottom:1rem!important; }                
.pl-3 { padding-left:1rem!important; }                              
.py-3 { padding-bottom:1rem!important;padding-top:1rem!important; }
.px-3 { padding-right:1rem!important;padding-left:1rem!important; }

.pt-4 { padding-top:1.5rem!important; }                             
.pr-4 { padding-right:1.5rem!important; }               
.pb-4 { padding-bottom:1.5rem!important; }              
.pl-4 { padding-left:1.5rem!important; }                                
.px-4 { padding-right:1.5rem!important;padding-left:1.5rem!important; }
.py-4 { padding-top:1.5rem!important;padding-bottom:1.5rem!important; }

.pt-5 { padding-top:3rem!important; }   
.pr-5 { padding-right:3rem!important; } 
.pb-5 { padding-bottom:3rem!important; }    
.pl-5 { padding-left:3rem!important; }  
.px-5 { padding-right:3rem!important;padding-left:3rem!important; }
.py-5 { padding-top:3rem!important;padding-bottom:3rem!important; }


/* DISPLAYS */

.d-flex { display: flex !important; }
.d-block { display: block !important; }
.d-i-block { display: inline-block !important; }
.d-none { display: none !important; }


/* FLEXBOX */

.flex-wrap { flex-wrap: wrap !important; }
.flex-column { flex-direction: column !important; }

.justify-content-between { justify-content: space-between; }
.justify-content-evenly { justify-content: space-evenly; }
.justify-content-around { justify-content: space-around; }
.justify-content-start { justify-content: flex-start !important; }
.justify-content-end { justify-content: flex-end; }
.justify-content-center { justify-content: center; }


.align-items-baseline { align-items: baseline !important; }
.align-items-center { align-items: center !important; }
.align-items-stretch { align-items: stretch !important; }
.align-items-end { align-items: flex-end !important; }
.align-items-start { align-items: flex-start !important; }


/* BUTTONS */

.btn {

  font-family: 'Poppins', sans-serif !important;
  background: transparent;
  padding: .75em 1em;
  border: none;
  outline: none;
  border-radius: .5rem;
  font-size: calc(1em - 2px);
  transition: .3s all ease;
  font-weight: 600;
  color: #000;
  cursor: pointer;
  &:disabled {
    opacity: .3;
  }
  &.btn-square {
    border-radius: 0 !important;
  }
  &.btn-clear {
    background: #FFF;
    color: #000;
  }
  &.btn-large {
    padding: .75rem 1.8rem;
    border-radius: 1em;
  }
  &:active {
    opacity: .7;
  }
  &.btn-orange {
    background: #F0A926;
    color: #FFF;
  }

  &.btn-blue {
    background: #42ACDE;
    color: #FFF;
  }
  &.btn-danger {
    background: #C50532;
    color: #FFF;
  }
  &.btn-gray {
    background: #E5E5E5;
    color: #000;
  }
  &.btn-dark {
    background: #000;
    color: #FFF;
  }
}

// COL


.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: calc(25% - .5rem);
}

.col-4 {
  width: calc(33.33% - 1rem);
}

.col-5 {
  width: 41.16%;
}

.col-6 {
  width: calc(50% - .5rem);
}


.col-7 {
  width: calc(58.33% - .5rem);
}
.col-8 {
  width: calc(66.66% - .5rem);
}


.col-12 {
  width: 100%;
}


@media screen and (max-width: 767px)
{
  .col-sm-12 {
    width: 100% !important;
  }
}


// FORM

.small-form {
  max-width: 400px;
  margin: 0 auto;
}

@media screen and (max-width: 767px)
{
  
}

.form-title {
  font-size: calc(1rem + 2px) !important;
  font-weight: 700;
  margin-bottom: 1rem;
}

label {
  display: block;
  color: #000;
  font-size: calc(1rem - 5px);
  font-weight: 400;
  margin-bottom: .6rem !important;
} 
.form-field {
  width: 100%;
  padding: .65rem 1.2rem;
  border: none;
  // border-bottom: 1px solid #ccc;
  border: 1px solid #E5E5E5;
  outline: none;
  transition: .3s all ease;
  font-size: calc(1rem - 4px);
  border-radius: 8px;
  background: #fff;
  color: #000;
  &::placeholder {
    color: #ccc;
  }
}
select.form-field {
  padding: .65rem 1.2rem;
}
textarea.form-field {
  resize: none;
  height: 80px;
}
.form-field:focus {
  border-color: #000;
}



// FONTS
.subtitle-text {
  color: #CCC !important;
  font-weight: 600;
  font-size: calc(1rem - 3px);
}

.black-text {
  color: #000 !important;
  font-weight: 400;
  font-size: calc(1rem - 2px);
}


.light-text {
  color: #000 !important;
  font-weight: 300;
  font-size: calc(1rem - 2px);
}

// POSITION

.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }


.border-bottom {
  border-bottom: 1px solid #CCC;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

// FONT WEIGHT

.fw-100 {
  font-weight: 100 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: bold !important;
}


.border-bottom-gray {
  border-bottom: 1px solid #CCC;
}

.width-fit-content {
  width: fit-content;
}


.text-gray { color: #ccc !important; }
.text-blue { color: #42ACDE !important; }
.text-red { color: #C50532 !important; }
.text-green { color: #0A602A !important; }
.text-yellow { color: #F0A926 !important; }
.text-center { text-align: center !important; }

.text-right { text-align: right !important; }



// ITEM CARD
.item-header {
  padding: 1rem;
  background-color: #C50532;
  color: #FFF;
  border-radius: 1rem;
  transition: .3s all ease;
  cursor: pointer;
  &.expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }
  
  &.valid {
      background-color: #0A602A;
      &.blue {
        background: #42ACDE !important;
      }
  }
}

.item-content {
  max-height: 0;
  transition: .25s max-height linear;
  padding: 0 0 !important;
  border: 1px solid transparent;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
  &.expanded {
      padding: 1rem !important;
      max-height: 500px;
      border-color: rgba(0, 0, 0, 0.205);
  }
}  




.modal {
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  div.modal-content {
    position: absolute;
    padding: 1rem;
    border-radius: 1rem;
    background: #FFF;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow: auto;
    transform: translate(-50%, -50%);
  }
}
.sticky-header {
  position: sticky;
  z-index: 4;
  top: -1px;
  background: #FFF;
}


input.search-input {
  border-radius: 1rem;
  border: none;
  padding: .55rem 1.75rem;
  outline: none;
  background: #E6F4FC;
  width: 14rem;
  min-width: 14rem;
}


.no-select {
  user-select: none !important;
}

@media screen and (max-width: 767px) {
  .no-mobile {
    display: none;
  }
}


.row-outlined {
  border: 1px solid #CCC;
  padding: .75rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: .5rem;
  user-select: none;
  cursor: pointer;
  transition: .3s opacity ease;
  position: relative;
  &.grid {
    padding-bottom: 1.2rem;
    min-height: 6rem;
    border-radius: 0 !important;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #00000029;
    div.form-group {
      width: 90%;
      margin: 0 auto;
    }
    &:nth-child(odd) {
      border-right: 1px solid #00000029;
    }
  }
  &:active {
    opacity: .55;
  }
  &.no-action {
    &:active {
      opacity: 1 !important;
    }
  }
  &.light {
    background: #F4F4F4;
    border-color: #F4F4F4;
    color: #000;
  }
  &.orange {
    background: #FFEEDA;
    border-color: #F28705;
    color: #000;
  }
  &.selected {
    background: #F5F8FA;
    border-color: #F5F8FA;
  }
  &.selected-2 {
    background: #E6F4FC;
    border-color: #E6F4FC;
  }
  .actions {
    height: 24px;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}


.flex-1-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  &.row-container {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  .flex-col {
    overflow-y: auto;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 1023px) {
  .hide-1023 {
    display: none;
  }
  .mw-500-1023 {
    max-width: 500px;
    width: 100%;
  }
  .mx-auto-1023 {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .hide-1024 {
    display: none;
  }
}

.fade-up {
  animation: fadeUp .3s ease-in;
}

@keyframes fadeUp {
  from {
    transform: translate(-50%, 50%);
  }
  to {
    transform: translateY(-50%, -50%);
  }
}

.font-6 { font-size: 0.375rem !important; }
.font-8 { font-size: 0.500rem !important; }
.font-9 { font-size: 0.5625rem !important; }
.font-10 { font-size: 0.625rem !important; }
.font-11 { font-size: 0.7rem !important; }
.font-12 { font-size: 0.750rem !important; }
.font-14 { font-size: 0.875rem !important; }
.font-16 { font-size: 1em !important; }
.font-18 { font-size: 1.125rem !important; }
.font-20 { font-size: 1.250rem !important; }
.font-24 { font-size: 1.5rem !important; }

.font-light { font-weight: 300; }
.font-medium { font-weight: 600; }
.font-normal { font-weight: 400; }
.font-italic { font-style: italic !important;}
.font-bold { font-weight: bold !important; }
.font-medium { font-weight: 600 !important; }
.font-500 {font-weight: 500 !important;}


.fs-9{font-size: 0.45rem !important; }
.fs-10{font-size: 0.5rem !important; }
.fs-11{font-size: 0.55rem !important; }
.fs-12{font-size: 0.6rem !important;}
.fs-14{font-size: 0.7rem !important;}
.fs-16{font-size: 0.8rem !important;}
.fs-18{font-size: 0.9rem !important;}
.fs-40{font-size: 2.5rem !important;}

.font-mediun-500{font-weight: 500;}


.line-height-0-6 {
    line-height: 0.6rem !important;
}

* {

  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

}